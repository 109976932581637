import dynamic from 'next/dynamic';

import type { CategoryCarouselSectionProps } from 'src/components/sections/CategoryCarouselSection/CategoryCarouselSection';
import { fetchCategoriesWithProducts } from 'src/services/catalog/fetchCategoriesWithProducts';
import type { SectionRenderer } from 'src/types/Section';
import type { AsyncTransformer } from 'src/types/Transformer';
import { lazyLoadImages } from 'src/utils/images';
import { categoryContentToModel } from './contentToModel';
import type { CategoryCarouselSectionContent, ContentCategory } from './types';
import { validateCategoryCarousel } from './validation';

const getCategoryProductPairs = (categories: ContentCategory[]) =>
  categories.map((item) => ({
    categoryId: item.category_id,
    productCode: item.product_code,
  }));

/* istanbul ignore next */
export const renderer: SectionRenderer<CategoryCarouselSectionProps> =
  dynamic<CategoryCarouselSectionProps>(() =>
    import('src/components/sections/CategoryCarouselSection/CategoryCarouselSection').then(
      (mod) => mod.CategoryCarouselSection,
    ),
  );

export const transformer: AsyncTransformer<
  CategoryCarouselSectionContent,
  CategoryCarouselSectionProps
> = async ({ _metadata, carousel_title, categories, tracking_title }, { positionMetadata }) => {
  const categoryProductPairs = getCategoryProductPairs(categories);
  const categoryProductData = await fetchCategoriesWithProducts(categoryProductPairs);

  return {
    uid: _metadata.uid,
    props: {
      title: carousel_title,
      categories: categoryProductData
        ? categoryContentToModel(categoryProductData, categories)
        : [],
      lazyImages: lazyLoadImages(positionMetadata.position),
      trackingTitle: tracking_title,
    },
    errors: validateCategoryCarousel(categories, categoryProductData),
  };
};
