import { addParamsToHref, fetchJSONWithTimeout } from '@noths/polaris-client-utils';
import getConfig from 'next/config';

import { logger } from 'src/utils/serverLogger';

const config = getConfig();

const { CATEGORY_CAROUSEL_API_PATH, CATEGORY_CAROUSEL_ENDPOINT } = config.publicRuntimeConfig;

export interface CategoryAndProductCode {
  categoryId: number;
  productCode: number;
}

export interface CatalogServiceCategoryAndProduct {
  heading: string;
  id: number;
  path: string;
  product_code: number;
  product_image: string;
  product_name: string;
}

export const fetchCategoriesWithProducts = async (
  categories: CategoryAndProductCode[],
): Promise<CatalogServiceCategoryAndProduct[] | null> => {
  try {
    const pairs = categories.map((category) => ({
      category_id: category.categoryId,
      product_code: category.productCode,
    }));
    const url = addParamsToHref(`${CATEGORY_CAROUSEL_ENDPOINT}${CATEGORY_CAROUSEL_API_PATH}`, {
      pairs: JSON.stringify(pairs),
    });
    const response = await fetchJSONWithTimeout(url, {
      method: 'GET',
      timeout: 1000,
    });
    const body = await response.json();

    // @ts-ignore
    return body.data;
  } catch (error) {
    logger.warn('failed to get category/product pair data', { error: `${String(error)}` });
  }

  return null;
};
